import React from "react";
// import PaymentForm from "../components/PaymentForm";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Button from "../components/Button";

const PaymentPage = () => {
  return (
    <>
      <Navbar />
      <div className="mt-28">
        <h2 className="text-4xl mb-12 text-center py-32">Get the official TEDxIITGandhinagar Merch!</h2>

        <div className="max-w-[68rem] mx-auto flex lg:flex-row flex-col items-center gap-10 px-4">
          <div className="w-full">
            <div className="relative w-full h-0 pb-[56.25%]">
              <iframe
                className="rounded-xl absolute h-full w-full top-0 left-0"
                title="round"
                allowFullScreen
                // width="500"
                // height="500"
                src="https://www.pacdora.com/share?filter_url=psio6690ru"
              ></iframe>
            </div>

            <div className="text-center py-4 text-xl">Round Neck T-shirt</div>
          </div>
          <div className="w-full">
            <div className="relative w-full  h-0 pb-[56.25%]">
              <iframe
                className="rounded-xl absolute h-full w-full top-0 left-0"
                title="polo"
                allowFullScreen
                // width="500"
                // height="500"
                src="https://www.pacdora.com/share?filter_url=ps6munhpwp"
              ></iframe>
            </div>
            <div className="text-center py-4 text-xl">Polo T-shirt</div>
          </div>
        </div>
      </div>
      <div className="w-fit mx-auto">
        <Button onClick={() => window.open("/buy-merch", "_blank")}>Buy now</Button>
      </div>

      {/* <PaymentForm /> */}
      <Footer />
    </>
  );
};

export default PaymentPage;
