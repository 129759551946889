import React from "react";
import "./SponsComp.css";

import titlespons from "../assets/images/GIFT CITY LOGO.png";
import cotitlespons from "../assets/images/co-title.png";
import Colours from "../utils/colours";
import platspon from "../assets/images/platinum_sponsor.webp";
// import goldspon from "../assets/images/gold_sponsor.webp";
// import titlespon from "../assets/images/title_sponsor.webp";
// import giftpartner from "../assets/images/gifting_partner.webp";

function Sponsors() {
  return (
    <div className="sponsors-page">
      <div className="lg:text-5xl text-4xl text-center py-20 mb-10 mt-20">Our Sponsors</div>
      <div className="max-w-[68rem] mx-auto mb-32 mt-8 flex xl:flex-row-reverse flex-col items-center gap-10 xl:py-4 py-0">
        <div className="xl:w-4/5 w-full px-10 flex items-center justify-center xl:-mr-[10%] ml-0">
          <img className="w-full h-full object-contain" src={titlespons} alt="" />
        </div>
        <div className="xl:w-3/5 w-full px-10 xl:-ml-[10%]">
          <div className="py-8 w-full">
            <div className="font-semibold uppercase tracking-wide google-sans py-4 w-fit text-sm lg:text-base" style={{ color: Colours.red }}>
              Title Sponsor
            </div>
            <div className="lg:text-5xl text-3xl font-thin google-sans w-fit">GIFT</div>
          </div>
          <div className="leading-9 tracking-wide google-sans w-fit text-left">
            India's first operational Smart City and IFSC, GIFT City, provides a global business platform with integrated infrastructure, cost benefits, and
            attractive tax policies. It targets key service sectors, aiming to compete with global financial hubs. Its 886 acres feature offices, residences,
            and advanced infrastructure. Developed by the Gujarat government and IL&FS, it's a sustainable, walk-to-work model. For more info visit :
            www.giftgujarat.in.
          </div>
        </div>
      </div>
      <div className="max-w-[68rem] mx-auto mb-32 mt-8 flex xl:flex-row flex-col items-center gap-10 xl:py-4 py-0">
        <div className="xl:w-4/5 w-full px-24 flex items-center justify-center relative xl:-ml-[10%] ml-0">
          <img className="w-full h-full object-contain rounded-xl" src={cotitlespons} alt="" />
        </div>
        <div className="xl:w-3/5 w-full px-10 xl:-ml-[10%]">
          <div className="py-8 w-full">
            <div className="font-semibold uppercase tracking-wide google-sans py-4 w-fit text-sm lg:text-base" style={{ color: Colours.red }}>
              Co-Title Sponsor
            </div>
            <div className="lg:text-5xl text-3xl font-thin google-sans w-fit text-left">Swachh Bharat Mission Urban - Gujarat</div>
          </div>
          <div className="leading-9 tracking-wide google-sans w-fit text-left">
            The Swachh Bharat Mission is a transformative movement that embodies the spirit of a New India - one that values dignity, health, and environmental
            sustainability. It fosters a cultural shift towards sustainable living, empowering citizens to contribute actively to a cleaner, greener future.
            With the vision to be the cleanest state of the country, Swachh Bharat Mission Urban-Gujarat envisions an India where cleanliness is not just a
            habit but a way of life - echoing across every city and every heart. For more info, visit : https://sbm-urban.gujarat.gov.in/Index
          </div>
        </div>
      </div>
      {/* <div className="sponsor-section">
        <h2>Gold Sponsor</h2>
        <div className="sponsor-logo">
          <img src={titlespon} alt="Title Sponsor Logo" />
          <p>INDEXT-a</p>
        </div>
      </div> */}
      <div className="sponsor-section ">
        <h2>Gold Sponsor</h2>
        <div className="sponsor-logo flex flex-col gap-4">
          <img src={platspon} alt="platinum spon Logo" />
          <p>Gujarat Tourism</p>
        </div>
      </div>
      <div className="sponsor-section ">
        <h2>Gold Sponsor</h2>
        <div className="sponsor-logo flex flex-col gap-4">
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2-Z61I42-kw6emsc76HDEsNZ2DFNwxaNViw&s" alt="Golden Sponsor Logo" />
          <p>Canara Bank</p>
        </div>
      </div>
      <div className="sponsor-section ">
        <h2>Silver Sponsor</h2>
        <div className="sponsor-logo flex flex-col gap-4">
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvYLGSOEfPbxg-TwF6zOuW1ntvuVMwIUEukw&s" alt="Golden Sponsor Logo" />
          <p>GUVNL</p>
        </div>
      </div>
      <div className="sponsor-section ">
        <h2>Silver Sponsor</h2>
        <div className="sponsor-logo flex flex-col gap-4">
          <img src="https://spvtoday.com/wp-content/uploads/2019/11/GEDA.jpg" alt="Golden Sponsor Logo" />
          <p>GEDA</p>
        </div>
      </div>
      <div className="sponsor-section ">
        <h2>Silver Sponsor</h2>
        <div className="sponsor-logo flex flex-col gap-4">
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmYDcPLKkzqlIZPH99K5odA5NEr3AudJDSGw&s" alt="Golden Sponsor Logo" />
          <p>Climate change department Gujarat</p>
        </div>
      </div>
      <div className="sponsor-section">
        <h2>Bronze Sponsor</h2>
        <div className="sponsor-logo flex flex-col gap-4">
          <img src="https://upload.wikimedia.org/wikipedia/en/d/df/GujaratMineralDevelopmentCorporationLogo.jpg" alt="Gifting Partner Logo" />
          <p>Gujarat Mineral Development Coorporation</p>
        </div>
      </div>
      <div className="sponsor-section">
        <h2>Bronze Sponsor</h2>
        <div className="sponsor-logo flex flex-col gap-4">
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ91Y7hKCUMCgFWWClq5qpHpxCJcaIDLZUnKg&s" alt="Gifting Partner Logo" />
          <p>Gujarat Maritime Board</p>
        </div>
      </div>

      <div className="sponsor-section">
        <h2>Media Partner</h2>
        <div className="sponsor-logo flex flex-col gap-4">
          <img
            src="https://media.licdn.com/dms/image/v2/C4D0BAQEtmYwMmPP4tw/company-logo_200_200/company-logo_200_200/0/1650010564765?e=2147483647&v=beta&t=haQO9Rd3TyR1o4CRV5xtutvH0zvqNiAtmqvRSOCDvnA"
            alt="Gifting Partner Logo"
          />
          <p>Tanza Talks</p>
        </div>
      </div>
      <div className="sponsor-section">
        <h2>Networking Dinner Partner</h2>
        <div className="sponsor-logo flex flex-col gap-4">
          <img
            src="https://media.licdn.com/dms/image/v2/C560BAQH5zb0hSOMQRQ/company-logo_200_200/company-logo_200_200/0/1630654135025/the_fern_residency_logo?e=2147483647&v=beta&t=AIS7-8LeTTp2sjN2z60JwdSM1Ev-800d3tinIECHbtk"
            alt="Gifting Partner Logo"
          />
          <p>Fern Residency</p>
        </div>
      </div>
    </div>
  );
}

export default Sponsors;
