import Navbar from "../components/Navbar";
import React from "react";
import Footer from "../components/Footer";
import TeamCard from "../components/TeamCard.jsx";

const licenseeData = [
  { name: "Devansh Lodha", department: "Design", role: "Licensee", image: "DevanshLodha" },
  { name: "Jeel Kalaria", department: "Events & Production", role: "Co-Licensee", image: "JeelKalaria" },
];

const teamData = [
  {
    team: "Cores And Coordinators",
    members: [
      { name: "Devansh Lodha", department: "Design", image: "DevanshLodha" },
      { name: "Karan Gandhi", department: "Tech", image: "KaranGandhi" },
      { name: "Dhairya Shah", department: "Design", image: "DhairyaShah" },
      { name: "Jeel Kalaria", department: "Events & Production", image: "JeelKalaria" },
      { name: "Dreamy Jain", department: "Events & Production", image: "Dreamy" },
      { name: "Mrugank Patil", department: "Events & Production", image: "Mrugank" },
      { name: "Kushal Mehta", department: "Sponsorship", image: "KushalMetha" },
      { name: "Diya Nandan", department: "Sponsorship", image: "DiyaNandan" },
      { name: "Pramith Joy", department: "Outreach", image: "PramithJoy" },
      { name: "Mayank Kumbhat", department: "Outreach", image: "MayankKumbhat" },
      { name: "Kaushal Bule", department: "Outreach", image: "KaushalBule" },
      { name: "Manushree Sonawane", department: "Curation", image: "ManushreeSonawane" },
      { name: "Anirudh Mittal", department: "Curation", image: "AnirudhMittal" },
      { name: "Himanshu Singh", department: "Finance", image: "Himanshu" },
    ],
  },
  {
    team: "Senior Team",
    members: [
      { name: "Aarsh Wankar", department: "Design and Tech", image: "AarshWankar" },
      { name: "Adil Khan", department: "Design and Tech", image: "AdilKhan" },
      { name: "Samarth Sonawane", department: "Design and Tech", image: "SamarthSonawane" },
      { name: "Prajas Kulkarni", department: "Design and Tech", image: "PrajasKulkarni" },
      { name: "Alok Shukla", department: "Design and Tech", image: "AlokShukla" },
      { name: "Anjali Ratna", department: "Design and Tech", image: "AnjaliRatna" },
      { name: "Trisha Biswas", department: "Design and Tech", image: "TrishaBiswas" },
      { name: "Arpita Kumawat", department: "Design and Tech", image: "ArpitaKumawat" },
      { name: "Apoorv Rane", department: "Events & Production", image: "ApoorvRane" },
      { name: "Chardiya Vanshri", department: "Events & Production", image: "Vanshri" },
      { name: "Niyati Siju", department: "Events & Production", image: "NiyatiSiju" },
      { name: "Parth Demble", department: "Events & Production", image: "ParthDembla" },
      { name: "Romit Mohane", department: "Events & Production", image: "Romit" },
      { name: "Shubham Saini", department: "Events & Production", image: "ShubhamSaini" },
      { name: "Hridya Balachandran", department: "Events & Production", image: "HridyaB" },
      { name: "Pravalli Matta", department: "Events & Production", image: "PravalliMatta" },
      { name: "Sruti Rekha Muni", department: "Events & Production", image: "SrutiMuni" },
      { name: "Ginisha Garg", department: "Curation", image: "GinishaGarg" },
      { name: "K R Tanvi", department: "Curation", image: "KRTanvi" },
      { name: "Sneha Bahadur", department: "Curation", image: "SnehaBahadur" },
      { name: "Anurag Singh", department: "Curation", image: "AnuragSingh" },
      { name: "Praneel Joshi", department: "Curation", image: "PraneelJoshi" },
      { name: "Manasvi Mandava", department: "Curation", image: "ManaswiMandava" },
      { name: "Venkatakrishnan E", department: "Outreach", image: "VenkatakrishnanE" },
      { name: "Chinmay Pendse", department: "Outreach", image: "ChinmayPendse" },
      { name: "Srikar Padisetti", department: "Outreach", image: "SrikarPadisetti" },
      { name: "Jainil Devaliya", department: "Sponsorship", image: "JainilDevaliya" },
      { name: "Nilay Verma", department: "Sponsorship", image: "NilayVerma" },
      { name: "Harshada Kale", department: "Sponsorship", image: "HarshadaKale" },
    ],
  },

  {
    team: "Junior Team",
    members: [
      // Design and Tech Team
      { name: "Radhika Purohit", department: "Design and Tech", image: "RadhikaPurohit" },
      // Design Team
      { name: "Anshika Poonia", department: "Design and Tech", image: "Anshika" },
      { name: "Aryan Meshram", department: "Design and Tech", image: "AryanMeshram" },
      { name: "Bhavya Shah", department: "Design and Tech", image: "BhavyaShah" },
      { name: "Eshaan Jaiswal", department: "Design and Tech", image: "EshaanJaiswal" },
      { name: "Laxmidhar Panda", department: "Design and Tech", image: "LaxmidharPanda" },

      // Events & Production Team
      { name: "Smit Bambhroliya", department: "Events & Production", image: "SmitBambhroliya" },
      { name: "Sayeed Ibrahim", department: "Events & Production", image: "SayeedIbrahim" },
      { name: "Vivek Sharma", department: "Events & Production", image: "VivekSharma" },
      { name: "Atharv Adsul", department: "Events & Production", image: "AtharvAdsul" },
      { name: "Pradeep Sain", department: "Events & Production", image: "PradeepSain" },
      { name: "Niraj Kumar", department: "Events & Production", image: "NirajKumar" },
      { name: "Shreyash Pandit", department: "Events & Production", image: "ShreyashPandit" },
      { name: "Yash Solanki", department: "Events & Production", image: "YashSolanki" },

      // Curation Team
      { name: "Harshvardhan Masalge", department: "Curation", image: "HarshvardhanMasalge" },
      { name: "Swamini More", department: "Curation", image: "SwaminiMore" },
      { name: "Aryan Lahoti", department: "Curation", image: "AryanLahoti" },
      // { name: "Akash Halder", department: "Curation", image: "AkashHalder" },
      { name: "Pratik Dhakar", department: "Curation", image: "PratikDhakar" },
      // { name: "Aratiben Goswami", department: "Curation", image: "AratiGoswami" },

      // Outreach Team
      { name: "Vivek Chaurasia", department: "Outreach", image: "VivekChaurasia" },
      { name: "Sanidhya Bhandari", department: "Outreach", image: "SanidhyaBhandari" },
      { name: "Heet Panchal", department: "Outreach", image: "HeetPanchal" },
      { name: "Jigar", department: "Outreach", image: "JigarMahedu" },
      { name: "Shubham Waghmode", department: "Outreach", image: "ShubhamWaghmode" },
      { name: "Pradnyesh Phadnis", department: "Outreach", image: "PradnyeshPhadnis" },
      { name: "Parth Singh", department: "Outreach", image: "ParthSingh" },
      { name: "Ajinder", department: "Outreach", image: "Ajinder" },

      // Sponsorship Team
      { name: "Yashaswini Ajay", department: "Sponsorship", image: "YashaswiniAjay" },
      { name: "Jash Pasad", department: "Sponsorship", image: "JashPasad" },
      { name: "Nakshatra Lad", department: "Sponsorship", image: "NakshatraLad" },
      { name: "Piyush Makhijani", department: "Sponsorship", image: "PiyushMakhijani" },
      { name: "Saksham Chourasia", department: "Sponsorship", image: "SakshamChourasia" },
    ],
  },
];

const OurTeamPage = () => {
  const seniorMembersByDept = {};
  const juniorMembersByDept = {};

  teamData.forEach(team => {
    if (team.team === "Senior Team" || team.team === "Junior Team") {
      team.members.forEach(member => {
        const targetObject = team.team === "Senior Team" ? seniorMembersByDept : juniorMembersByDept;

        if (!targetObject[member.department]) {
          targetObject[member.department] = [];
        }
        targetObject[member.department].push(member);
      });
    }
  });

  return (
    <>
      <Navbar />
      <div className="max-w-[68rem] mx-auto mb-32 mt-8">
        <div className="mb-14"></div>
        <div className="pt-32 bg-black text-white min-h-screen">
          <div className="text-white mb-32 lg:text-5xl text-4xl text-center">Our Team</div>

          {/* Licensee & Co-Licensee Section */}
          <div className="mb-16">
            <div className="flex flex-wrap justify-center gap-12">
              {licenseeData.map((member, idx) => (
                <TeamCard member={member} key={idx} />
              ))}
            </div>
            {/* <h2 className="text-4xl text-center text-white mb-12">Licensee & Co-Licensee</h2> */}
          </div>

          {/* Core Team & Coordinators */}
          {teamData
            .filter(team => team.team !== "Senior Team" && team.team !== "Junior Team")
            .map((team, index) => (
              <div key={index} className="mb-16">
                <h2 className="lg:text-4xl text-3xl text-center text-white mb-8 py-10">{team.team}</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-12 justify-center items-center">
                  {team.members.map((member, idx) => (
                    <TeamCard member={member} key={idx} />
                  ))}
                </div>
              </div>
            ))}

          {/* Senior Team - Department Wise */}
          <div className="py-16 text-center">
            <h2 className="lg:text-4xl text-3xl mb-8">Senior Team</h2>
            {Object.keys(seniorMembersByDept).map((dept, index) => (
              <div key={index} className="mb-10">
                <h3 className="text-3xl text-white mb-4 py-10">{dept}</h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-center">
                  {seniorMembersByDept[dept].map((member, idx) => (
                    <TeamCard member={member} key={idx} />
                  ))}
                </div>
              </div>
            ))}
          </div>

          {/* Junior Team - Department Wise */}
          <div className="pt-16">
            <h2 className="lg:text-4xl text-3xl text-center mb-8">Junior Team</h2>
            {Object.keys(juniorMembersByDept).map((dept, index) => (
              <div key={index} className="mb-10">
                <h3 className="text-3xl text-white mb-4 text-center py-10">{dept}</h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-center">
                  {juniorMembersByDept[dept].map((member, idx) => (
                    <TeamCard member={member} key={idx} />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OurTeamPage;
