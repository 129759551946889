import designImages from "../TeamPhotos/TeamPhotos/Design&Tech/index.js";
import eventsImages from "../TeamPhotos/TeamPhotos/Events&Production/index.js";
import outreachImages from "../TeamPhotos/TeamPhotos/Outreach/index.js";
import sponsorshipImages from "../TeamPhotos/TeamPhotos/Sponsorship/index.js";
import curationImages from "../TeamPhotos/TeamPhotos/Curation/index.js";
import financeImages from "../TeamPhotos/TeamPhotos/Finance/index.js";

// Mapping department names to their respective image objects
const imageSources = {
  Tech: designImages,
  "Design and Tech": designImages,
  Design: designImages,
  "Events & Production": eventsImages,
  Outreach: outreachImages,
  Sponsorship: sponsorshipImages,
  Curation: curationImages,
  Finance: financeImages,
};

const getImage = (department, imageName) => {
  const images = imageSources[department]; // Get the corresponding image object
  console.log(images);
  return images && images[imageName.replace(/\.[^/.]+$/, "")] // Remove extension to match key
    ? images[imageName.replace(/\.[^/.]+$/, "")]
    : "/default-profile.jpg"; // Fallback image if not found
};

const TeamCard = ({ member }) => {
  return (
    <div className="relative w-[17rem] h-80 shadow-lg mx-auto bg-neutral-800 transform transition duration-300 hover:scale-105 hover:shadow-2xl rounded-xl">
      <div className="relative w-[17rem] h-80 mx-auto overflow-hidden rounded-xl">
        <img src={getImage(member.department, member.image)} alt={member.name} className="w-full h-full object-cover" />
      </div>
      <div className="bg-white text-black absolute bottom-0 mb-2 left-4 right-4 px-4 py-2 rounded-xl text-left">
        <div className="text-xl">{member.name}</div>
        <div className="text-neutral-700">{member.role || member.department + " Team"}</div>
      </div>
    </div>
  );
};

export default TeamCard;
