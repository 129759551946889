const Podcast = ({ title, embed, desc }) => {
  return (
    <div className="py-10">
      <div>
        <div className="text-2xl py-4">{title}</div>
        <div className="text-neutral-400">{desc}</div>
      </div>
      <div className="w-full h-0 pb-[56.25%] relative">{embed}</div>
    </div>
  );
};

export default Podcast;
