const images = {
  DiyaNandan: require("./DiyaNandan.jpeg"),
  JainilDevaliya: require("./JainilDevaliya.jpg"),
  JashPasad: require("./JashPasad.jpeg"),
  NakshatraLad: require("./NakshatraLad.jpg"),
  NilayVerma: require("./NilayVerma .png"),
  PiyushMakhijani: require("./PiyushMakhijani.png"),
  SakshamChourasia: require("./SakshamChourasia.jpg"),
  YashaswiniAjay: require("./YashaswiniAjay.jpg"),
  KushalMetha: require("./KushalMehta.png"),
  HarshadaKale: require("./HarshadaKale.png"),
};

export default images;
