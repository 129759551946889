const images = {
  Ajinder: require("./Ajinder.jpg"),
  ChinmayPendse: require("./ChinmayPendse.jpg"),
  JigarMahedu: require("./JigarMahedu.jpeg"),
  KaushalBule: require("./KaushalBule.jpg"),
  MayankKumbhat: require("./MayankKumbhat.jpg"),
  ParthSingh: require("./ParthSingh.jpeg"),
  PradnyeshPhadnis: require("./PradnyeshPhadnis.jpg"),
  PramithJoy: require("./PramithJoy.jpg"),
  SanidhyaBhandari: require("./SanidhyaBhandari.jpg"),
  ShubhamWaghmode: require("./ShubhamWaghmode.jpg"),
  SrikarPadisetti: require("./SrikarPadisetti.jpeg"),
  VenkatakrishnanE: require("./VenkatakrishnanE.jpg"),
  VivekChaurasia: require("./VivekChaurasia.jpeg"),
  HeetPanchal: require("./HeetPanchal.jpeg"),
};

export default images;
