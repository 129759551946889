const images = {
    Dreamy: require("./Dreamy.jpg"),
    ApoorvRane: require("./ApoorvRane.jpeg"),
    AtharvAdsul: require("./AtharvAdsul.jpg"),
    HridyaB: require("./HridyaB.jpeg"),
    JeelKalaria: require("./JeelKalaria.png"),
    NirajKumar: require("./NirajKumar.jpg"),
    NiyatiSiju: require("./NiyatiSiju.jpg"),
    ParthDembla: require("./ParthDembla.jpg"),
    PradeepSain: require("./PradeepSain.jpg"),
    PravalliMatta: require("./PravalliMatta.jpg"),
    SayeedIbrahim: require("./SayeedIbrahim.jpeg"),
    ShreyashPandit: require("./ShreyashPandit.jpg"),
    ShubhamSaini: require("./ShubhamSaini.jpg"),
    SmitBambhroliya: require("./SmitBambhroliya.jpg"),
    SrutiMuni: require("./SrutiMuni.jpeg"),
    Vanshri: require("./Vanshri.png"),
    VivekSharma: require("./VivekSharma.jpg"),
    // YashSolanki: require("./YashSolanki.jpg"),
    YashSolanki: require("./YashSolanki.jpg"),
    Romit: require("./Romit.jpeg"),
    Mrugank: require("./Mrugank.jpeg"),
  };
  
  export default images;
  