import PastEventCard from "./PastEventCard";
import podcat from "../assets/images/thumbnail_comp.jpeg";
import episteme from "../assets/images/vr.jpg";
import pastSpeakers from "../assets/images/slide1.webp";

const PastEvents = () => {
  return (
    <div className="max-w-[68rem] mx-auto py-16 px-8" id="events">
      <div>
        <div className="text-center lg:text-4xl text-3xl py-10">Past Events</div>
        <div className="flex lg:flex-row flex-col items-center gap-10">
          <PastEventCard img={podcat} text="TEDxConversations" link="/conversations" />
          <PastEventCard img={episteme} text="Episteme" link="/workshops" />
          <PastEventCard img={pastSpeakers} text="Past Speakers" link="/past-speakers" />
        </div>
      </div>
    </div>
  );
};

export default PastEvents;
