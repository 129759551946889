import { motion } from "framer-motion";
import Button from "../Button";
import Slide from "../Slide";
import { Link } from "react-router-dom";
import slide1 from "../../assets/theme_stuff/yt_thumbnail_compress.png";

const TEDxWorkshopsSlide1 = () => {
  return (
    <Slide backgroundURL={slide1}>
      <motion.span
        className="text-4xl mb-4 text-center"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        Our Theme — The Cosmos Within
      </motion.span>
      <motion.span className="text-xl text-center" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.8, delay: 0.4 }}>
        This year's theme "The Cosmos Within" explores the intricate connections between the universe and our inner worlds.
      </motion.span>
      <motion.span className="mt-5" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.8, delay: 0.6 }}>
        <Link to="https://youtu.be/sWn-GvIeMOk?si=QgDJ2CFNKL3C0x99">
          <Button>Theme Video</Button>
        </Link>
      </motion.span>
    </Slide>
  );
};

export default TEDxWorkshopsSlide1;
