const images = {
  AarshWankar: require("./AarshWankar.jpg"),
  AdilKhan: require("./AdilKhan.jpeg"),
  AlokShukla: require("./AlokShukla.jpg"),
  AnjaliRatna: require("./AnjaliRatna.jpeg"),
  Anshika: require("./Anshika.jpg"),
  ArpitaKumawat: require("./ArpitaKumawat.jpg"),
  AryanMeshram: require("./AryanMeshram.jpg"),
  BhavyaShah: require("./BhavyaShah.jpeg"),
  DhairyaShah: require("./DhairyaShah.PNG"),
  EshaanJaiswal: require("./EshaanJaiswal.jpg"),
  KaranGandhi: require("./KaranGandhi.jpg"),
  LaxmidharPanda: require("./LaxmidharPanda.jpg"),
  PavitrChandwani: require("./PavitrChandwani.jpg"),
  PrajasKulkarni: require("./PrajasKulkarni .jpg"),
  RadhikaPurohit: require("./RadhikaPurohit.jpg"),
  SamarthSonawane: require("./SamarthSonawane.jpeg"),
  ShreyaWani: require("./ShreyaWani.jpeg"),
  TrishaBiswas: require("./TrishaBiswas.jpg"),
  DevanshLodha: require("./DevanshLodha.jpeg"),
};

export default images;
