import React, { useEffect } from "react";
import { useLocation } from "react-router-dom"; // Import Link from react-router-dom
import Navbar from "../components/Navbar";
import LandingSlideshow from "../components/LandingSlideshow";
import { toast, ToastContainer } from "react-toastify";
import About from "../components/About";
import Footer from "../components/Footer";
import Quote from "../components/Quote";
import EventDetails from "../components/EventDetails";
import Speakers from "../components/Speakers";
// import Sponsors from "../components/Sponsors";
import ThemeComponent from "../components/ThemeComponent";
import OurVision from "../components/OurVision";
import PastEvents from "../components/PastEvents";

const HomeRoute = () => {
  const location = useLocation();

  useEffect(() => {
    console.log("Location state:", location.state);
    if (location.state && location.state.showToast) {
      toast.success("Registration Successful! Tickets will be sent through email.");
    }
  }, [location]);

  return (
    <>
      <Navbar />
      <LandingSlideshow />
      <Quote
        text="The world as we have created it is a process of our thinking. It cannot be changed without changing our thinking. When we embrace new ideas, challenge our assumptions, and step into the unknown, we find the power to shape not just our own future, but the future of the world."
        subtext="Inspired by Albert Einstein"
      />
      <ToastContainer />
      <About />
      <OurVision />
      <ThemeComponent />
      <Speakers />
      {/* <Sponsors /> */}
      <EventDetails
        eventName="Attend TEDxIIT Gandhinagar 2025"
        eventDesc="Attending a live TEDx event is interactive, engaging and exciting experience with other TED-loving people in our amazing college IIT Gandhinagar."
        eventVenue={["@ Jasubhai Auditorium", "IIT Gandhinagar, Gujarat"]}
        eventTime={["23rd March "]}
      />
      <PastEvents />
      <Footer />
    </>
  );
};

export default HomeRoute;
