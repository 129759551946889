import { Link } from "react-router-dom";
import { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useScroll } from "../hooks/useScroll";
import HamburgerIcon from "../icons/HamburgerIcon";
import useOutsideAlerter from "../hooks/useOutsideAlerter";
import TEDxIITGNLogo from "../assets/images/TEDx_Logo_Short.png";

const styles = {
  active: {
    visibility: "visible",
    transition: "all 0.5s",
    backgroundColor: "rgb(0 0 0 / 50%)",
  },
  hidden: {
    backgroundColor: "rgb(0 0 0 / 50%)",
    visibility: "hidden",
    transition: "all 0.5s",
    transform: "translateY(-100%)",
  },
};

const Navbar = ({ dontHide, wide }) => {
  const [menuIsOpen, setMenuOpen] = useState(false);
  const { scrollDirection } = useScroll();

  const navbarRef = useRef(null);
  useOutsideAlerter(navbarRef, () => setMenuOpen(false));

  return (
    <nav>
      <div
        className="fixed w-full top-0 left-0 backdrop-blur z-50"
        style={scrollDirection === "down" || dontHide ? styles.active : styles.hidden}
        ref={navbarRef}
      >
        <div className={"antialiased max-w-[70rem] py-6 px-4 mx-auto left-0 " + (wide ? "max-w-4xl" : "")}>
          <div className="flex justify-between items-center">
            <div className="text-[1.3rem]">
              {/* <span style={{ color: Colours.red }}>TEDx</span>IITGandhinagar */}
              <a href="/#">
                <img src={TEDxIITGNLogo} alt="" className="w-[12rem]" />
              </a>
            </div>
            <div className="hidden lg:flex gap-8">
              <a href="/#">
                <span className="nav-link">Home</span>
              </a>
              <a href="/#about">
                <span className="nav-link">About</span>
              </a>
              <a href="/#events">
                <span className="nav-link">Past Events</span>
              </a>
              <Link to="/sponsors">
                <span className="nav-link">Sponsors</span>
              </Link>
              <Link to="/schedule">
                <span className="nav-link">Schedule</span>
              </Link>
              <Link to="/team">
                <span className="nav-link">Our Team</span>
              </Link>
              <a href="mailto:tedxiitgandhinagar@iitgn.ac.in">
                <span className="nav-link">Contact</span>
              </a>
            </div>
            <div className="block lg:hidden">
              <HamburgerIcon open={menuIsOpen} onClick={() => setMenuOpen(!menuIsOpen)} />
            </div>
          </div>
        </div>
      </div>
      <div
        className={"flex flex-col pb-4 pt-5 fixed transition backdrop-blur w-full top-[56px] z-40 " + (menuIsOpen ? "" : "-translate-y-full opacity-0")}
        style={{
          backgroundColor: "rgb(0 0 0 / 50%)",
        }}
      >
        <div className="py-2 my-1 px-4">
          <a href="/#">
            <span className="nav-link">Home</span>
          </a>
        </div>
        <div className="py-2 my-1 px-4">
          <a href="/#about">
            <span className="nav-link">About</span>
          </a>
        </div>
        <div className="py-2 my-1 px-4">
          <a href="/#events">
            <span className="nav-link">Past Events</span>
          </a>
        </div>

        <div className="py-2 my-1 px-4">
          <Link to="/sponsors">
            <span className="nav-link">Sponsors</span>
          </Link>
        </div>

        <div className="py-2 my-1 px-4">
          <Link to="/schedule">
            <span className="nav-link">Schedule</span>
          </Link>
        </div>

        <div className="py-2 my-1 px-4">
          <Link to="/team">
            <span className="nav-link">Our Team</span>
          </Link>
        </div>

        <div className="py-2 my-1 px-4">
          <a href="mailto:tedxiitgandhinagar@iitgn.ac.in">
            <span className="nav-link">Contact</span>
          </a>
        </div>
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  dontHide: PropTypes.bool,
  wide: PropTypes.bool,
};

export default Navbar;
