import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import schedule from "../assets/images/web_schedule.png";

const SponsPage = () => {
  return (
    <>
      <Navbar />
      <div>
        <div className="max-w-[68rem] mx-auto mt-8 flex xl:flex-row-reverse flex-col items-center gap-10 pt-20">
          <img src={schedule} alt="" />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SponsPage;
