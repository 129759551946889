import { auth, provider, signInWithPopup } from "../utils/firebaseConfig";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PaymentPopup from "./PaymentPopup";
import img from "../assets/images/qr_diya.jpeg";
import Button from "./Button";

const PaymentForm = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    size_1: "",
    size_2: "",
    type_1: "",
    type_2: "",
    merch_name_1: "",
    merch_name_2: "",
  });

  // eslint-disable-next-line no-unused-vars
  const [discountedAmount, setDiscountedAmount] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [showPopup, setShowPopup] = useState(false);

  const handleGoogleLogin = e => {
    e.preventDefault();
    signInWithPopup(auth, provider)
      .then(result => {
        const email = result.user.email;
        console.log(email);
        if (email) {
          setFormData({
            ...formData,
            email,
          });
        } else {
        }
      })
      .catch(error => {
        console.error("Error during sign-in", error);
        alert("Please open this site in chrome and login again");
      });
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log(name, value);
  };

  const handleSuccess = () => {
    navigate("/", { state: { showToast: true } });
  };

  const handleCheckout = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    // navigate("/");
  };

  useEffect(() => {
    let total = 0;
    if (formData.type_1 === "polo") {
      total += 450;
    } else if (formData.type_1 === "round_neck") {
      total += 350;
    }

    if (formData.type_2 === "polo") {
      total += 450;
    } else if (formData.type_2 === "round_neck") {
      total += 350;
    }

    if (formData.type_1 === "polo" && formData.type_2 === "polo") {
      total = 800;
    } else if (formData.type_1 === "round_neck" && formData.type_2 === "round_neck") {
      total = 650;
    } else if (formData.type_1 === "polo" && formData.type_2 === "round_neck") {
      total = 700;
    } else if (formData.type_1 === "round_neck" && formData.type_2 === "polo") {
      total = 700;
    }

    setDiscountedAmount(total);
  }, [formData.type_1, formData.type_2]);

  const collectFormData = () => {
    return {
      name: formData.name,
      email: formData.email,
      contactNumber: formData.phone,
      nameOnMerch1: formData.merch_name_1,
      nameOnMerch2: formData.merch_name_2,
      size1: formData.size_1,
      size2: formData.size_2,
      merch1: formData.type_1,
      merch2: formData.type_2,

      totalAmount: discountedAmount.toFixed(2), // Moved this outside
    };
  };

  return (
    <div className="flex bg-black text-white p-8 pt-40 rounded-lg shadow-lg max-w-4xl mx-auto py-32">
      <div className="form-section w-full pr-4">
        <h2 className="text-4xl mb-12 text-center">Buy Merch</h2>
        <form>
          <div className="mb-4">
            <div className="text-neutral-400 py-4">
              {/* For special discounts please use your IITGN email. */}
              <div className="text-lg text-white">Offers:</div> <br />
              Polo : 450
              <br />
              Round Neck : 350 <br />
              Polo + Round Neck : 700
              <br />
              Two Polo : 800
              <br />
              Two Round Neck : 650
              <br />
              <br />
              All order can be picked up from IIT Gandhinagar campus on 23rd March
              <br />
              (Please Note that the confirmation will be sent to the email address associated with your Google Account. If you are facing any issues in logging
              in, please switch to Chrome browser)
            </div>
            <Button onClick={() => window.open("size_chart_polo.png", "_blank")} className="rounded-xl mr-2">
              Open Size Chart for Polo
            </Button>
            <Button onClick={() => window.open("size_chart_round_neck.png", "_blank")} className="rounded-xl">
              Open Size Chart for Round Neck
            </Button>
          </div>
          {formData.email.length > 0 && <label className="block mb-2 text-neutral-300">Email: {formData.email}</label>}

          {!formData.email && (
            <Button onClick={handleGoogleLogin} className="rounded-xl">
              Login with Google
            </Button>
          )}
          <br />
          <div className="mb-4">
            <label className="block mb-2 text-neutral-300">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="bg-transparent focus:outline-0 px-4 w-full py-2.5 rounded-md"
              style={{ backgroundColor: "#171717" }}
            />
          </div>

          <div className="mb-4">
            <label className="block mb-2 text-neutral-300">Phone Number</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              className="bg-transparent focus:outline-0 px-4 w-full py-2.5 rounded-md"
              style={{ backgroundColor: "#171717" }}
            />
          </div>
        </form>

        <>
          <div className="text-white py-2">First Merch Details</div>

          {/* <div className="mb-4">
            <label className="block mb-2 text-neutral-300">Name to be put on Merch</label>
            <input
              type="text"
              name="merch_name_1"
              value={formData.merch_name_1}
              onChange={handleInputChange}
              className="bg-transparent focus:outline-0 px-4 w-full py-2.5 rounded-md"
              style={{ backgroundColor: "#171717" }}
            />
          </div> */}
          <div className="mb-4">
            <label className="block mb-2 text-neutral-300">Select Size</label>
            <select
              name="size_1"
              value={formData.size_1 || ""}
              onChange={handleInputChange}
              className="bg-transparent focus:outline-0 px-4 w-full py-2.5 rounded-md"
              style={{ backgroundColor: "#171717" }}
            >
              <option value="" disabled>
                Select your size
              </option>
              <option value="S">S</option>
              <option value="M">M</option>
              <option value="L">L</option>
              <option value="XL">XL</option>
              <option value="2XL">2XL</option>
              <option value="3XL">3XL</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-neutral-300">Select type</label>
            <select
              name="type_1"
              value={formData.type_1 || ""}
              onChange={handleInputChange}
              className="bg-transparent focus:outline-0 px-4 w-full py-2.5 rounded-md"
              style={{ backgroundColor: "#171717" }}
            >
              <option value="" disabled>
                Select type
              </option>
              <option value="polo">Polo</option>
              <option value="round_neck">Round neck</option>
            </select>
          </div>

          <div className="text-white py-2">Second Merch Details (Leave blank if you're purchasing only one)</div>
          {/* <div className="mb-4">
            <label className="block mb-2 text-neutral-300">Name to be put on Merch</label>
            <input
              type="text"
              name="merch_name_2"
              value={formData.merch_name_2}
              onChange={handleInputChange}
              className="bg-transparent focus:outline-0 px-4 w-full py-2.5 rounded-md"
              style={{ backgroundColor: "#171717" }}
            />
          </div> */}

          <div className="mb-4">
            <label className="block mb-2 text-neutral-300">Select Size</label>
            <select
              name="size_2"
              value={formData.size_2 || ""}
              onChange={handleInputChange}
              className="bg-transparent focus:outline-0 px-4 w-full py-2.5 rounded-md"
              style={{ backgroundColor: "#171717" }}
            >
              <option value="" disabled>
                Select your size
              </option>
              <option value="S">S</option>
              <option value="M">M</option>
              <option value="L">L</option>
              <option value="XL">XL</option>
              <option value="2XL">2XL</option>
              <option value="3XL">3XL</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-neutral-300">Select type</label>
            <select
              name="type_2"
              value={formData.type_2 || ""}
              onChange={handleInputChange}
              className="bg-transparent focus:outline-0 px-4 w-full py-2.5 rounded-md"
              style={{ backgroundColor: "#171717" }}
            >
              <option value="" disabled>
                Select type
              </option>
              <option value="polo">Polo</option>
              <option value="round_neck">Round neck</option>
            </select>
          </div>
          <div className="text-lg  mt-4">
            {/* <span className="">Actual Price: ₹{totalAmount} </span> <br />
              <span className="">Discount: ₹{totalAmount - discountedAmount.toFixed(2)} </span> <br /> */}
            <span className="text-red-400">Final Amount: ₹{discountedAmount.toFixed(2)}</span>
          </div>
          <Button
            disabled={formData.email === ""}
            onClick={handleCheckout}
            className={`mt-4 p-2 rounded-xl ${formData.email === "" ? "opacity-50 cursor-not-allowed" : ""}`}
          >
            Checkout
          </Button>
          {showPopup && formData.email !== "" && (
            <PaymentPopup onClose={handleClosePopup} img={img} totalAmount={discountedAmount} formData={collectFormData()} onSuccess={handleSuccess} />
          )}
        </>
      </div>

      <div className="hidden lg:flex justify-center items-center flex-1">
        <img src={img} alt="QR Code" className="h-64" />
      </div>
    </div>
  );
};

export default PaymentForm;
