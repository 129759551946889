import { motion } from "framer-motion";
import Button from "../Button";
import Slide from "../Slide";
import { Link } from "react-router-dom";
import slide1 from "../../assets/images/thumbnail_comp.jpeg";

const TEDxWorkshopsSlide2 = () => {
  return (
    <Slide backgroundURL={slide1}>
      <motion.span
        className="text-4xl mb-4 text-center"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        TEDxConversations — A series of Podcasts
      </motion.span>
      <motion.span className="text-xl text-center" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.8, delay: 0.4 }}>
        Insightful Podcasts, One Idea at a Time.
      </motion.span>
      <motion.span className="mt-5" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.8, delay: 0.6 }}>
        <Link to="/conversations">
          <Button>More Info</Button>
        </Link>
      </motion.span>
    </Slide>
  );
};

export default TEDxWorkshopsSlide2;
