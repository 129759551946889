import React from "react";
import Navbar from "../components/Navbar";
import LandingSlideshow from "../components/WorkshopLandingSlideshow";
import Footer from "../components/Footer";
import Quote from "../components/Quote";
import EventDetails from "../components/EventDetails";
import Speakers from "../components/Speakers";
import Sponsors from "../components/Sponsors";

const WorkshopRoute = () => {
  return (
    <>
      <Navbar />
      <LandingSlideshow />
      <Quote
        text="The world as we have created it is a process of our thinking. It cannot be changed without changing our thinking. When we embrace new ideas, challenge our assumptions, and step into the unknown, we find the power to shape not just our own future, but the future of the world."
        subtext="Inspired by Albert Einstein"
      />
      {/* <ToastContainer /> */}
      {/* <About /> */}
      {/* <OurVision /> */}
      {/* <ThemeComponent /> */}
      <Speakers />
      <Sponsors />
      <EventDetails
        eventName="Attend TEDxIITGandhinagar 2025"
        eventDesc="Attending a live TEDx event is interactive, engaging and exciting experience with other TED-loving people in our amazing college IIT Gandhinagar."
        eventVenue={["@ IIT Gandhinagar", "Gandhinagar, Gujarat"]}
        eventTime={["27th October,", "9:00 AM — 7:00 PM"]}
      />
      <Footer />
    </>
  );
};

export default WorkshopRoute;
