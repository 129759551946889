// import Colours from "../utils/colours";
import AnimatedText from "./AnimatedText";
import planet from "../assets/theme_stuff/astronaut_planet_comp.jpeg";

const OurVision = () => {
  return (
    <div className="max-w-[68rem] mx-auto mb-32 mt-8 flex xl:flex-row flex-col items-center gap-10 xl:py-16 py-0" id="ourVision">
      <div className="xl:w-4/5 w-full px-10 flex items-center justify-center relative xl:-ml-[10%] ml-0">
        <img className="w-full h-full object-contain" src={planet} alt="" />
      </div>
      <div className="xl:w-3/5 w-full px-10">
        <div className="py-8">
          <AnimatedText text="Our Vision" className="lg:text-5xl text-3xl font-thin google-sans"></AnimatedText>
        </div>
        <AnimatedText
          className="leading-9 tracking-wide google-sans"
          text="We aim to create TEDxIITGandhinagar'25 as not just an event, but a catalyst for change. As students of IIT Gandhinagar, we strive to create an environment that inspires, fosters intellectual growth, and promotes meaningful connections in our community. As we set our sights on TEDxIITGandhinagar'25, our aspirations are grounded in creating an exceptional event that transcends boundaries and leaves a lasting impact on all participants"
        />
      </div>
    </div>
  );
};

export default OurVision;
