import { isLgScreen } from "../utils/BrowserUtils";
import Colours from "../utils/colours";
// import Button from "./Button";
// import { Link } from "react-router-dom";

const EventDetails = ({ eventName, eventDesc, eventVenue, eventTime }) => {
  if (isLgScreen())
    return (
      <div className="px-10">
        <div className="max-w-[71rem] mx-auto pt-16 pb-10 px-16 bg-neutral-800 rounded-2xl mt-16">
          <div className="grid grid-cols-3 grid-rows-2 items-start gap-5" style={{ gridTemplateColumns: "40% 30% 30%" }}>
            <div className="lg:text-4xl text-2xl">
              <span>{eventName}</span>
            </div>
            <div className="self-end uppercase font-semibold text-sm mb-2" style={{ color: Colours.red }}>
              where
            </div>
            <div className="self-end uppercase font-semibold text-sm mb-2" style={{ color: Colours.red }}>
              when
            </div>
            <div className="text-neutral-300">
              <span>{eventDesc}</span>
            </div>
            <div className="flex flex-col gap-3">
              <div className="text-2xl">{eventVenue[0]}</div>
              <div>{eventVenue[1]}</div>
            </div>
            <div className="flex flex-col text-2xl">
              <div>{eventTime[0]}</div>
              <div>{eventTime[1]}</div>
            </div>
          </div>
          {/* <div className="max-w-[68rem] mx-auto mt-5">
            <Link to="/payment">
              <Button>Buy Tickets</Button>
            </Link>
          </div> */}
        </div>
      </div>
    );
  else
    return (
      <div className="px-10">
        <div className="max-w-[76rem] mx-auto lg:pt-16 pt-12 pb-10 lg:px-16 px-8 bg-neutral-800 rounded-2xl mt-16">
          <div className="flex flex-col items-start gap-5">
            <div className="lg:text-4xl text-2xl">
              <span>{eventName}</span>
            </div>
            <div className="text-neutral-300">
              <span>{eventDesc}</span>
            </div>
            <div className="flex justify-between flex-col">
              <div className="uppercase font-semibold text-sm mb-2" style={{ color: Colours.red }}>
                where
              </div>
              <div className="flex flex-col gap-3">
                <div className="text-2xl">{eventVenue[0]}</div>
                <div>{eventVenue[1]}</div>
              </div>
            </div>
            <div className="">
              <div className="uppercase font-semibold text-sm mb-2" style={{ color: Colours.red }}>
                when
              </div>
              <div className="flex flex-col text-2xl">
                <div>{eventTime[0]}</div>
                <div>{eventTime[1]}</div>
              </div>
            </div>
          </div>
          {/* <div className="max-w-[68rem] mx-auto mt-5">
            <Link to="/payment">
              <Button>Buy Tickets</Button>
            </Link>
          </div> */}
        </div>
      </div>
    );
};

export default EventDetails;
