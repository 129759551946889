import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Speaker from "../components/Speaker";
import AkshaySinghal from "../assets/images/AkshaySinghal.jpeg";
import AmitabhSingh from "../assets/images/AmitabhSingh.png";
import AnuragSingh from "../assets/images/AnuragSingh.png";
import Harun from "../assets/images/Harun.png";
import Kush from "../assets/images/Kush.png";
import Neeti from "../assets/images/Neeti.png";
import Nitin from "../assets/images/Nitin.png";
import Rajat from "../assets/images/Rajat.png";
import Reema from "../assets/images/Reema.png";
import Vidhya from "../assets/images/Vidhyadhar.png";
import Amit from "../assets/images/Amit.jpg";
import Anil from "../assets/images/Anil.jpg";
import Praveen from "../assets/images/Praveen.jpg";
import Revathi from "../assets/images/Revathi.jpg";
import Ishkaran from "../assets/images/Ishkaran.jpg";
import Dutee from "../assets/images/Dutee.jpg";
import Shreya from "../assets/images/Shreya.jpg";

const speakerInfo = [
  {
    season: 3,
    speakers: [
      {
        name: "Amit Agarwal",
        photo: "Amit.jpg",
        desc: "Amit Agarwal, the Salespreneur®, is a global sales expert, author, and evangelist of life skills, blending material success with spiritual growth.",
        talkLink: "https://www.youtube.com/watch?v=Ep3VNVb1JC8",
        img: Amit,
      },
      {
        name: "Anil Chhikara",
        photo: "Anil.jpg",
        desc: "Serial entrepreneur, emerging OTT actor, and a driving force in India's startup ecosystem with a diverse range of talents and accomplishments",
        talkLink: "https://www.youtube.com/watch?v=azXwLP98EVo",
        img: Anil,
      },
      {
        name: "Dutee Chand",
        photo: "Dutee.jpg",
        desc: "India's sprint queen, a record-breaking athlete, and a trailblazer for gender rights in sports",
        talkLink: "https://www.youtube.com/watch?v=F-ZSVJTflGQ",
        img: Dutee,
      },
      {
        name: "Ishkaran Singh Bhandari",
        photo: "Ishkaran.jpg",
        desc: "Supreme Court advocate, political analyst, and social activist, known for simplifying complex legal and political issues.",
        talkLink: "https://www.youtube.com/watch?v=U76j1J9iN0U",
        img: Ishkaran,
      },
      {
        name: "Praveen Kumar Vemula",
        photo: "Praveen.jpg",
        desc: "A pioneering researcher and entrepreneur transforming biomaterials and drug delivery into impactful biomedical innovations.",
        talkLink: "https://www.youtube.com/watch?v=TBrU2cNJvNc",
        img: Praveen,
      },
      {
        name: "Revathi Ramanan",
        photo: "Revathi.jpg",
        desc: "Commonwealth Scholar and Senior Director at Teach For India, driving educational equity and leadership across regions.",
        talkLink: "https://www.youtube.com/watch?v=gWquXiTLFDs",
        img: Revathi,
      },
      {
        name: "Shreya Poonja",
        photo: "Shreya.jpg",
        desc: "1st Runner-Up of Femina Miss India 2023, a rising star in the beauty and fashion industry.",
        talkLink: "https://www.youtube.com/watch?v=bJ15TfZPL8w",
        img: Shreya,
      },
    ],
  },
  {
    season: 2,
    speakers: [
      {
        name: "Akshay Singhal",
        photo: "AkshaySinghal.jpeg",
        desc: "Forbes Under 30 Asia awardee and leader in EV battery innovation at Log9 Materials.",
        talkLink: "https://www.youtube.com/watch?v=DvdxLUBM7GI",
        img: AkshaySinghal,
      },
      {
        name: "Amitabha Singh",
        photo: "AmitabhSingh.png",
        desc: "Internationally acclaimed cinematographer known for his work on award-winning films and series.",
        talkLink: "https://www.youtube.com/watch?v=dI7xo5tyAag",
        img: AmitabhSingh,
      },
      {
        name: "Anurag Chauhan",
        photo: "AnuragSingh.png",
        desc: "Founder of Humans for Humanity and advocate for menstrual hygiene and women's empowerment in rural India.",
        talkLink: null,
        img: AnuragSingh,
      },
      {
        name: "NITIN AGGARWAL",
        photo: "Nitin.png",
        desc: "Head of Cloud AI Services at Google India, with over 10 years of expertise in AI and machine learning",
        talkLink: null,
        img: Nitin,
      },
      {
        name: "Reema Nanavaty",
        photo: "Reema.png",
        desc: "Padma Shri awardee and Director of SEWA, empowering self-employed women and advocating for gender equality.",
        talkLink: "https://www.youtube.com/watch?v=h9B506gzxkk",
        img: Reema,
      },
    ],
  },
  {
    season: 1,
    speakers: [
      {
        name: "Khurshed Batliwala",
        photo: "Kush.png",
        desc: "Wellbeing expert, author, and meditation teacher dedicated to holistic personal growth and healing.",
        talkLink: "https://youtu.be/zHwMHHWwYWc",
        img: Kush,
      },
      {
        name: "Vidyadhar Oke",
        photo: "Vidhya.png",
        desc: "Renowned polymath excelling in Indian classical music, medicine, astrology, and interdisciplinary innovation.",
        talkLink: "https://www.youtube.com/watch?v=ipYLnhC5YDo",
        img: Vidhya,
      },
      {
        name: "Neeti Kailas",
        photo: "Neeti.png",
        desc: "Innovative leader skilled in brand management, growth strategy, and cross-functional team leadership.",
        talkLink: "https://www.youtube.com/watch?v=-uJDMNTM8kU",
        img: Neeti,
      },
      {
        name: "Rajat Nagpal",
        photo: "Rajat.png",
        desc: "Rajat Nagpal is a multidisciplinary creative force—a filmmaker, educator, strategist, and culinary performer—using storytelling and design to inspire and heal.",
        talkLink: "https://www.youtube.com/watch?v=uNAF90ImMOE",
        img: Rajat,
      },
      {
        name: "Harun Robert",
        photo: "Harun.png",
        desc: "Renowned artist, YouTuber, and TV personality inspiring creativity through DIY and artistic ventures.",
        talkLink: "https://www.youtube.com/watch?v=9CpJE2_cSuo",
        img: Harun,
      },
    ],
  },
];

const PastSpeakersRoute = () => {
  return (
    <>
      <Navbar />
      <div className="max-w-[68rem] mx-auto mt-28">
        <div className="lg:text-5xl text-4xl text-center py-10">Past Speakers</div>

        <div>
          {speakerInfo.map(season => (
            <div key={season.season} className="my-10">
              <div className="text-4xl text-center">Season {season.season}</div>
              <div className="flex flex-col gap-10">
                {season.speakers.map(speaker => (
                  <Speaker name={speaker.name} tagline={speaker.desc} profileLink={speaker.talkLink} talk photo={speaker.img} />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PastSpeakersRoute;
