import Colours from "../utils/colours";
import AnimatedText from "./AnimatedText";

const Theme = () => {
  return (
    <div className="max-w-[68rem] mx-auto mb-32 mt-8 flex xl:flex-row-reverse flex-col items-center gap-10 xl:py-16 py-0" id="theme">
      <div className="xl:w-4/5 w-full px-10 flex items-center justify-center relative h-0 pb-[56.25%] xl:-mr-[10%] ml-0">
        <iframe
          className="absolute w-full h-full top-0 left-0 px-10 lg:px-0"
          src="https://www.youtube.com/embed/sWn-GvIeMOk?si=hqbHVH0tkGhAaiG9"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
      <div className="xl:w-3/5 w-full px-10 xl:-ml-[10%]">
        <div className="py-8">
          <AnimatedText
            text="Our Theme"
            className="font-semibold uppercase tracking-wide google-sans text-sm lg:text-base"
            style={{ color: Colours.red }}
          ></AnimatedText>
          <AnimatedText text="The Cosmos Within" className="lg:text-5xl text-3xl font-thin google-sans"></AnimatedText>
        </div>
        <AnimatedText
          className="leading-9 tracking-wide google-sans"
          text={`"The Cosmos Within" explores the intricate connections between the universe and our inner worlds. It celebrates the boundless creativity, resilience, and potential within each of us while drawing parallels to the mysteries and vastness of the cosmos. This theme invites thinkers, dreamers, and doers to share perspectives that illuminate the inner and outer universe.`}
        />
      </div>
    </div>
  );
};

export default Theme;
