import title from "../assets/images/title_sponsor.webp";
import gold from "../assets/images/gold_sponsor.webp";
import platinum from "../assets/images/platinum_sponsor.webp";
import gifting_partner from "../assets/images/gifting_partner.webp";

const Sponsors = () => {
  return (
    <div className="max-w-[68rem] mx-auto flex flex-col justify-center py-6" id="sponsors">
      <div className="lg:text-5xl text-4xl text-center py-10 mb-10">Past Sponsors</div>
      <div className="flex flex-wrap justify-center">
        <div className="m-4 flex flex-col gap-4 items-center text-lg">
          <img src={title} alt="IIT Gandhinagar" className="h-40 w-40" />
          {/* Title Sponsor */}
        </div>
        <div className="m-4 flex flex-col gap-4 items-center text-lg">
          <img src={platinum} alt="IIT Gandhinagar" className="h-40 w-40" />
          {/* Platinum Sponsor */}
        </div>
        <div className="m-4 flex flex-col gap-4 items-center text-lg">
          <img src={gold} alt="IIT Gandhinagar" className="object-cover h-40 w-[22rem]" />
          {/* Gold Sponsors */}
        </div>
        <div className="m-4 flex flex-col gap-4 items-center text-lg">
          <img src={gifting_partner} alt="IIT Gandhinagar" className="h-40 w-40" />
          {/* Gifting Partner */}
        </div>
      </div>
    </div>
  );
};
export default Sponsors;
