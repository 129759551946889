const images = {
    AkashHalder: require("./AkashHalder.JPG"),
    AnirudhMittal: require("./AnirudhMittal.jpg"),
    AnuragSingh: require("./AnuragSingh.jpg"),
    AratiGoswami: require("./AratiGoswami.jpg"),
    AryanLahoti: require("./AryanLahoti.jpg"),
    GinishaGarg: require("./GinishaGarg.jpg"),
    HarshvardhanMasalge: require("./HarshvardhanMasalge .jpg"),
    HimanshuSingh: require("./HimanshuSingh.jpeg"),
    KRTanvi: require("./KRTanvi.jpg"),
    ManaswiMandava: require("./ManaswiMandava.jpeg"),
    ManushreeSonawane: require("./ManushreeSonawane.jpg"),
    PraneelJoshi: require("./PraneelJoshi.jpg"),
    PratikDhakar: require("./PratikDhakar.jpg"),
    SnehaBahadur: require("./SnehaBahadur.jpeg"),
    SwaminiMore: require("./SwaminiMore.jpg"),
  };
  
  export default images;
  