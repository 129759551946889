import React from "react";
import PaymentForm from "../components/PaymentForm";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const PaymentPage = () => {
  return (
    <>
      <Navbar />
      <PaymentForm />
      <Footer />
    </>
  );
};

export default PaymentPage;
