import Speaker from "./Speaker";
// import caperment from "../assets/images/capermint.webp";

const Speakers = () => {
  return (
    <div className="max-w-[68rem] mx-auto flex flex-col justify-center py-6" id="workshops">
      <div className="lg:text-5xl text-4xl text-center py-10">Our Speakers</div>
      <Speaker
        name="Aanal Kotak"
        photo="https://media.licdn.com/dms/image/v2/C4D03AQHGb3G7TWtjrQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657028768878?e=2147483647&v=beta&t=ZlF1AzE23L6AZYFYM4OntxfmtStdDQZd4F8xeavmmcU"
        desc="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium saepe quasi cumque! Voluptates iusto exercitationem minima voluptatibus hic
          quidem"
        tagline={
          <div>
            <div className="mb-4">
              MasterChef India star, award winning entrepreneur and culinary innovator who has turned her love for food into an empire.
            </div>
            <div>Time: 10:30 AM, Jasubhai Auditorium</div>
            {/* <div>Time: 27th October, 9:00 AM — 12:00 PM</div> */}
          </div>
        }
        profileLink="https://www.instagram.com/aanalkotak/?hl=en"
      />
      <Speaker
        name="Gaurav Juyal"
        photo="https://pbs.twimg.com/profile_images/1263366743067521024/601KhqFB_400x400.jpg"
        desc="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium saepe quasi cumque! Voluptates iusto exercitationem minima voluptatibus hic
        quidem"
        tagline={
          <div>
            <div className="mb-4">The man who turned art into an adventure, inspiring millions to unleash their creativity!</div>
            <div>Time: 2:45 PM, Jasubhai Auditorium</div>
          </div>
        }
        profileLink="https://www.instagram.com/the.millennial.guide/?hl=en"
      />
      <Speaker
        name="Ritika Anand"
        photo="https://m.media-amazon.com/images/M/MV5BNjlhOWZlNDQtNDg3Ni00Y2VjLTgzYzktYzk1YTE0OTA0MjBiXkEyXkFqcGc@._V1_.jpg"
        desc="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium saepe quasi cumque! Voluptates iusto exercitationem minima voluptatibus hic
        quidem"
        tagline={
          <div>
            <div className="mb-4">
              A powerhouse in acting, filmmaking and storytelling! She is a versatile actress, producer and a two times published author, podcast host and
              founder of We Women Foundation
            </div>
            <div>Time: 11:45 AM, Jasubhai Auditorium</div>
          </div>
        }
        profileLink="https://www.instagram.com/ritikact_11/?hl=en"
      />
      <Speaker
        name="Punit G"
        photo="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZ3G50rPQuxtiNd2aHwfEFCNnrEpVHXxx8Sg&s"
        desc="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium saepe quasi cumque! Voluptates iusto exercitationem minima voluptatibus hic
        quidem"
        tagline={
          <div>
            <div className="mb-4">
              Dr Punit G's journey spans diverse worlds, from being an accidental veterinarian to becoming a renowned global business leader.
            </div>
            <div>Time: 4:45 PM, Jasubhai Auditorium</div>
          </div>
        }
        profileLink="https://www.linkedin.com/in/drpunitg/?originalSubdomain=in"
      />
      <Speaker
        name="Dr Manish Gupta"
        photo="https://pbs.twimg.com/profile_images/1037181832180064256/cyGI7SC6_400x400.jpg"
        desc="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium saepe quasi cumque! Voluptates iusto exercitationem minima voluptatibus hic
        quidem"
        tagline={
          <div>
            <div className="mb-4">
              He is a global leader in computing and Artificial Intelligence, he is currently serving as the Director of Google Research India.
            </div>
            <div>Time: 12:30 PM, Jasubhai Auditorium</div>
          </div>
        }
        profileLink="https://www.linkedin.com/in/manish-gupta-4556591/?originalSubdomain=in"
      />
      <Speaker
        name="Devendra Jhajharia"
        photo="https://upload.wikimedia.org/wikipedia/commons/b/bc/Devendra_Jhajharia.jpg"
        desc="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium saepe quasi cumque! Voluptates iusto exercitationem minima voluptatibus hic
        quidem"
        tagline={
          <div>
            <div className="mb-4">
              He is India's most decorated Paralympian, holding two Paralympic Gold Medals from Athens 2004 and Rio 2016 and a silver medal from Tokyo 2020
            </div>
            <div>Time: 3:45 PM, Jasubhai Auditorium</div>
          </div>
        }
        profileLink="https://www.instagram.com/devendra_jhajharia_/?hl=en"
      />
      <Speaker
        name="Ridhi Singhai"
        photo="https://images.yourstory.com/cs/wordpress/2018/02/Ridhi.jpg?fm=auto&ar=2:1&mode=crop&crop=faces"
        desc="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium saepe quasi cumque! Voluptates iusto exercitationem minima voluptatibus hic
        quidem"
        tagline={
          <div>
            <div className="mb-4">She is an entrepreneur dedicated to revolutionizing everyday products for a sustainable future. </div>
            <div>Time: 11:00 AM, Jasubhai Auditorium</div>
          </div>
        }
        profileLink="https://www.linkedin.com/in/ridhi-singhai-24610a104/?originalSubdomain=in"
      />

      <Speaker
        name="Captain Akhilesh Saxena"
        photo="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSljnNs-Ujrad4QVNDrhqC3gSrME8aoBF3-lw&s"
        desc="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium saepe quasi cumque! Voluptates iusto exercitationem minima voluptatibus hic
        quidem"
        tagline={
          <div>
            <div className="mb-4">
              A decorated veteran who lead critical assaults on Tololing Peak and Three Pimples during Kargil War - which earned him the Parakram Padak
            </div>
            <div>Time: 2:15 PM, Jasubhai Auditorium</div>
          </div>
        }
        profileLink="https://captainakhileshsaxena.com/"
      />
    </div>
  );
};

export default Speakers;
