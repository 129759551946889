const PastEventCard = ({ img, text, link }) => {
  return (
    <div className="relative lg:w-1/3 w-full h-96 cursor-pointer hover:scale-105 transition" onClick={() => window.open(link, "_blank")}>
      <img src={img} alt="" className="w-full h-full object-cover md:rounded-xl brightness-50" />
      <div className="absolute bottom-0 text-lg p-6 left-0">{text}</div>
    </div>
  );
};

export default PastEventCard;
