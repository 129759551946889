import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Import Routes instead of Switch
import HomeRoute from "./routes/HomeRoute";
import Merch from "./pages/MerchPage";
import PaymentPage from "./pages/PaymentPage";
import SponsPage from "./pages/SponsPage";
import OurTeamPage from "./pages/OurTeamPage";
import ConversationsRoute from "./routes/ConversationsRoute";
import WorkshopRoute from "./routes/WorkshopsRoute";
import PastSpeakersRoute from "./routes/PastSpeakersRoute";
import SchedulePage from "./pages/SchedulePage";

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Use Routes instead of Switch */}
        <Route path="/" element={<HomeRoute />} /> {/* Use element prop instead of component */}
        <Route path="/conversations" element={<ConversationsRoute />} /> {/* Use element prop instead of component */}
        <Route path="/past-speakers" element={<PastSpeakersRoute />} /> {/* Use element prop instead of component */}
        <Route path="/workshops" element={<WorkshopRoute />} /> {/* Use element prop instead of component */}
        <Route path="/merch" element={<Merch />} />
        <Route path="/buy-merch" element={<PaymentPage />} />
        <Route path="/team" element={<OurTeamPage />} />
        <Route path="/sponsors" element={<SponsPage />} />
        <Route path="/schedule" element={<SchedulePage />} />
      </Routes>
    </Router>
  );
};

export default App;
